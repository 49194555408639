<template>
  <b-card>
    <FormView
      :data="$store.getters['creditPackage/detailData']"
      :fields="fields"
      @load="loadData"
    />
  </b-card>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormView,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'storeProductID',
          label: 'Store Product ID',
        },
        {
          key: 'amount',
          label: 'Amount',
          type: 'gold',
        },
        // all value is in usd
        {
          key: 'price',
          label: 'Price',
          prefix: '$',
          suffix: ' USD',
        },
        {
          key: 'discount',
          label: 'Discount',
          suffix: '%',
        },
        {
          key: 'createdAt',
          label: 'Created at',
          type: 'datetime',
        },
        {
          key: 'updatedAt',
          label: 'Updated at',
          type: 'datetime',
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('creditPackage/getDetail', id)
    },
  },
}
</script>

    <style scoped>

    </style>
